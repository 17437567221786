// Core Imports
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import auth from '@/core/auth'
import cache from '@/core/cache'
import store from '@/core/store'
import VueSweetalert2 from 'vue-sweetalert2'
import Snotify from 'vue-snotify'
import Vue2TouchEvents from 'vue2-touch-events'
// import moment from '@/core/moment-config'
import VueSocialSharing from 'vue-social-sharing'
import { EmojiPickerPlugin } from 'vue-emoji-picker'

import './config/json'
// import './config/prototypes'
import './config/window'
import './config/zendesk'
import './config/validations/main'
import './config/validations/localizations'
import './config/validations/extends'

import SharedData from '@/plugins/SharedData'
import AuthPlugin from '@/plugins/AuthPlugin'
import ServerCache from '@/plugins/ServerCache'
import NProgress from '@/plugins/nprogress'
import VueCurrencyFilter from 'vue-currency-filter'
import DateTimeFilter from '@/filters/datetime'
import DateFilter from '@/filters/date'
import DownloadUrlFilter from '@/filters/downloadUrl'
import TimeFilter from '@/filters/time'
import DateAgoFilter from '@/filters/dateago'
import RelativeDateFilter from '@/filters/relativeDate'
import RelativeDateSimpleFilter from '@/filters/relativeDateSimple'
import Clipboard from 'v-clipboard'
// import * as VueGoogleMaps from 'vue2-google-maps'
import Vue2CurrencyInput from 'vue-currency-input'
import VueSilentbox from 'vue-silentbox'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// import { ToggleButton } from 'vue-js-toggle-button'
// import ToggleButton from 'vue-js-toggle-button/src/Button';

/**
 * 3rd Parts Imports
 * We should add the 3rd part libs imports under this comment
 */
import VueVirtualScroller from 'vue-virtual-scroller'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

/**
 * Global Custom Directive Imports
 * we should add the global custom directive imports under this comment
 */

import IfPermitted from './directives/IfPermitted'
import LazyLoad from './directives/LazyLoad'
import Focus from '@/directives/Focus'
import ShadowHtml from '@/directives/ShadowHtml'
import ClickOutside from 'vue-click-outside'

// import Lowercase from './directives/Lowercase'
// import Uppercase from './directives/Uppercase'

/**
 * Style (css, scss) Imports
 * We should add the style things imports under this comment
 */
import './assets/scss/main.scss'

// import wb from './registerServiceWorker'
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'

// Senty Configuration
import sentry from './config/sentry'
sentry(router)

// Vue.prototype.$workbox = wb
Vue.prototype.$hostname = ['localhost', '127.0.0.1'].some(p => window.location.href.includes(p))
  ? 'http://localhost:8080/'
  : window.location.href.includes('test.pixelkultur.net')
    ? 'https://test.pixelkultur.net/'
    : 'https://my.pixelkultur.net/'

Vue.prototype.$hostnameMain = ['localhost', '127.0.0.1'].some(p => window.location.href.includes(p))
  ? 'http://localhost:3030/'
  : window.location.href.includes('test.pixelkultur.net')
    ? 'https://test.pixelkultur.net:3002/'
    : 'https://pixelkultur.net/'

Vue.prototype.$hostnameSPA = ['localhost', '127.0.0.1'].some(p => window.location.href.includes(p))
  ? 'http://localhost:3000/'
  : window.location.href.includes('test.pixelkultur.net')
    ? 'https://test.pixelkultur.net:3001/'
    : 'https://dienstleisterportal.de/'

store.init()
auth.init()
cache.init()

// Fontawesome configurations
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// 3th party components
Vue.component('VueMultiselectOriginal', () => import('vue-multiselect'))
Vue.component('downloadExcel', () => import('vue-json-excel'))
Vue.component('VueContentLoading', () => import('vue-content-loading'))
Vue.component('vue-virtual-scroll-list-old', () => import('vue-virtual-scroll-list_old'))
Vue.component('vue-virtual-scroll-list', () => import('vue-virtual-scroll-list').then(m => m.default))
Vue.component('VueGoogleAutocomplete', () => import('vue-google-autocomplete'))
Vue.component('tinymce-editor', () => import('@tinymce/tinymce-vue'))
Vue.component('VueDatePicker', () => import('@sum.cumo/vue-datepicker'))
// Vue.component('DateRangePicker', () => import('vue2-daterange-picker'))
Vue.component('DatePicker2', () => import('vue2-datepicker'))
Vue.component('vue-timepicker', () => import('vue2-timepicker'))
Vue.component('VueCropper', () => import('vue-cropperjs'))
// Vue.component('NprogressContainer', () => import('vue-nprogress/src/NprogressContainer'))
Vue.component('VueBootstrapTypeahead', () => import('vue-typeahead-bootstrap'))
Vue.component('ToggleButton', () => import('vue-js-toggle-button').then(m => m.ToggleButton))
Vue.component('Draggable', () => import('vuedraggable'))
Vue.component('GoogleCaptcha', () => import('vue2-recaptchav3'))

// General lazy loading components
Vue.component('LightBoxModal', () => import('@/components/LightBoxModal'))
Vue.component('NprogressContainer', () => import('@/components/BxpNprogressContainer'))
Vue.component('AppIcon', () => import('@/components/AppIcon'))
Vue.component('AppBadge', () => import('@/components/AppBadge'))
Vue.component('AppButton', () => import('@/components/AppButton'))
Vue.component('AppCard', () => import('@/components/AppCard'))
Vue.component('dropdown-multi', () => import('@/components/DropdownMulti'))
Vue.component('Multiselect', () => import('@/components/VueMultiselect.vue'))
Vue.component('Permission', () => import('@/components/Permission'))
Vue.component('DownloadFile', () => import('@/components/DownloadFile'))
Vue.component('OrderIcon', () => import('@/components/OrderIcon'))
Vue.component('MoreWorkActions', () => import('@/views/work/action-buttons/MoreWorkActions.vue'))
Vue.component('ListInput', () => import('@/components/shared/forms/ListInput'))
Vue.component('Datepicker', () => import('@/components/shared/forms/BxpDatepicker.vue'))
Vue.component('BxpDatePickerNew', () => import('@/components/shared/forms/BxpDatePickerNew.vue'))
Vue.component('BxpDateRangePicker', () => import('@/components/shared/forms/BxpDateRangePicker.vue'))
Vue.component('BxpDatetimePicker', () => import('@/components/shared/forms/BxpDatetimePicker.vue'))
Vue.component('BxpFileUploader', () => import('@/components/shared/forms/BxpFileUploader.vue'))
Vue.component('VueCurrencyInput', () => import('@/components/shared/forms/VueCurrencyInput.vue'))
Vue.component('ActionButtonContainer', () => import('@/views/work/ActionButtonContainer'))
Vue.component('PaginateColumn', () => import('@/components/PaginateColumn'))
Vue.component('TableColumnFilter', () => import('@/components/tables/TableColumnFilter'))
Vue.component('TextFilter', () => import('@/components/tables/columns/filters/TextFilter'))
Vue.component('Paginator', () => import('@/components/Paginator'))
Vue.component('FeatureBoard', () => import('@/components/features/FeatureBoard.vue'))
Vue.component('FeatureData', () => import('@/components/features/FeatureData.vue'))
Vue.component('FeatureBox', () => import('@/components/FeatureBox.vue'))
Vue.component('SearchBox', () => import('@/components/SearchBox'))
Vue.component('Notifications', () => import('@/components/Notifications'))
Vue.component('FeatureDataSingle', () => import('@/components/features/FeatureDataSingle.vue'))
Vue.component('FeatureDataMultiple', () => import('@/components/features/FeatureDataMultiple.vue'))
Vue.component('FeatureForm', () => import('@/components/features/FeatureForm.vue'))
Vue.component('BxpClickToEditSimple', () => import('@/components/shared/forms/BxpClickToEditSimple.vue'))
Vue.component('ImagePreview', () => import('@/components/files/ImagePreview.vue'))
Vue.component('FileUploadList', () => import('@/components/files/FileUploadList.vue'))
Vue.component('FileUploadAll', () => import('@/components/files/FileUploadAll.vue'))
Vue.component('BxpClickToEditDetection', () => import('@/components/shared/forms/BxpClickToEditDetection.vue'))
Vue.component('BxpImageUploader', () => import('@/components/shared/forms/BxpImageUploader.vue'))
Vue.component('BxpPdfViewerButton', () => import('@/components/shared/forms/BxpPdfViewerButton.vue'))
Vue.component('BxpPdfContent', () => import('@/components/shared/forms/BxpPdfContent.vue'))
Vue.component('OrderSelectBox', () => import('@/components/shared/forms/OrderSelectBox'))
Vue.component('SearchListBox', () => import('@/components/shared/forms/SearchListBox'))
Vue.component('ContactSelectBox', () => import('@/components/shared/forms/ContactSelectBox'))
Vue.component('ContactSearch', () => import('@/components/shared/forms/ContactSearch'))
Vue.component('BxpCustomFieldsView', () => import('@/components/shared/forms/BxpCustomFieldsView.vue'))
Vue.component('BxpCustomFieldsForm', () => import('@/components/shared/forms/BxpCustomFieldsForm.vue'))
Vue.component('BxpAddress', () => import('@/components/shared/forms/BxpAddress.vue'))
Vue.component('PhoneInput', () => import('@/components/shared/forms/PhoneInput.vue'))
Vue.component('OldPhoneInput', () => import('@/components/shared/forms/OldPhoneInput.vue'))
Vue.component('BxpEbillReader', () => import('@/components/shared/forms/BxpEbillReader.vue'))
Vue.component('PartnerSelection', () => import('@/views/work/modals/PartnerSelection.vue'))
Vue.component('Change', () => import('@/components/shared/ContactChange'))
Vue.component('BxpTextEditor', () => import('@/components/shared/forms/BxpTextEditor.vue'))
Vue.component('DescriptionBox', () => import('@/components/shared/forms/DescriptionBox.vue'))
Vue.component('SideBar', () => import('@/views/home/SideBar.vue'))
Vue.component('OpenWorksBox', () => import('@/views/home/dashboard/OpenWorksBox.vue'))
Vue.component('NewDealsBox', () => import('@/views/home/dashboard/NewDealsBox.vue'))
Vue.component('StatisticBox', () => import('@/views/home/dashboard/StatisticBox.vue'))
Vue.component('StatisticBoxSectionNew', () => import('@/views/home/dashboard/StatisticBoxSectionNew.vue'))
Vue.component('BusinessTeamStatsBox', () => import('@/views/home/dashboard/BusinessTeamStatsBox.vue'))
Vue.component('TaskBox', () => import('@/views/home/dashboard/TaskBox.vue'))
Vue.component('ResubmissionBox', () => import('@/views/home/dashboard/ResubmissionBox.vue'))
Vue.component('AppointmentBox', () => import('@/views/home/dashboard/AppointmentBox.vue'))
Vue.component('PlacetelBox', () => import('@/views/home/dashboard/PlacetelBox.vue'))
Vue.component('LostStatsBox', () => import('@/views/home/dashboard/LostStatsBox.vue'))
Vue.component('LostStatsGraphBox', () => import('@/views/home/dashboard/LostStatsGraphBox.vue'))
Vue.component('AwardStatsBox', () => import('@/views/home/dashboard/AwardStatsBox.vue'))
Vue.component('AwardStatsBox2', () => import('@/views/home/dashboard/AwardStatsBox2.vue'))
Vue.component('AnnouncementBox', () => import('@/views/home/dashboard/AnnouncementBox.vue'))
Vue.component('PersonalMessages', () => import('@/components/PersonalMessages'))
Vue.component('PersonalMessagesNew', () => import('@/components/PersonalMessagesNew'))
Vue.component('NavigationAddNewMenu', () => import('@/components/NavigationAddNewMenu'))
Vue.component('PlacetelOverlay', () => import('@/components/PlacetelOverlay.vue'))
Vue.component('CurrentWorkspace', () => import('@/components/CurrentWorkspace.vue'))
Vue.component('ScreenSubscriptions', () => import('@/components/ScreenSubscriptions'))
// Vue.component('WorkHeader', () => import('@/views/work/parts/WorkHeader.vue'))
// Vue.component('TrackingLinkBox', () => import('@/views/work/boxes/TrackingLinkBox.vue'))
// Vue.component('DocumentBox', () => import('@/views/work/boxes/DocumentBox.vue'))
// Vue.component('OfferBox', () => import('@/views/work/boxes/OfferBox.vue'))
Vue.component('RelationsBox', () => import('@/views/work/boxes/RelationsBox.vue'))
Vue.component('BxpTable', () => import('@/components/shared/BxpTable.vue'))
Vue.component('BxpTablePro', () => import('@/components/shared/BxpTablePro.vue'))
Vue.component('Currency', () => import('@/components/Currency'))
Vue.component('BxpBaseDataManager', () => import('@/views/settings/base-data/tabs/BxpBaseDataManager.vue'))
Vue.component('BxpMdcTable', () => import('@/components/shared/forms/BxpMdcTable.vue'))
Vue.component('AppCustomTextEditor', () => import('@/components/shared/forms/AppCustomTextEditor.vue'))
Vue.component('BxpAgGridTable', () => import('@/components/shared/BxpAgGridTable.vue'))
Vue.component('SingleFileUpload', () => import('@/components/files/SingleFileUpload.vue'))
Vue.component('BxpTableData', () => import('@/components/shared/BxpTableData.vue'))
Vue.component('BxpTableMdcData', () => import('@/components/shared/BxpTableMdcData.vue'))
Vue.component('ImageCropper', () => import('@/components/files/ImageCropper'))
Vue.component('UserCard', () => import('@/views/user/UserCard.vue'))
Vue.component('Tab', () => import('@/components/Tab.vue'))
Vue.component('FileDropArea', () => import('@/components/files/FileDropArea'))
Vue.component('TaxRateInput', () => import('@/components/shared/forms/TaxRateInput'))
Vue.component('GeneralSearchBox', () => import('@/components/shared/forms/GeneralSearchBox'))
Vue.component('ProductContactSelection', () => import('@/components/shared/forms/ProductContactSelection'))
Vue.component('Chat', () => import('@/components/chat/Chat.vue'))
Vue.component('Dashboard', () => import('@/views/home/Dashboard'))
Vue.component('NewsFeed', () => import('@/views/home/NewsFeed'))
Vue.component('ActivityFeeds', () => import('@/views/home/ActivityFeeds'))
Vue.component('WorkQueryInput', () => import('@/components/shared/forms/WorkQueryInput'))
Vue.component('BillActionButtonContainer', () => import('@/views/bills/BillActionButtonContainer'))
Vue.component('ProvisionActionButtonContainer', () => import('@/views/bills/ProvisionActionButtonContainer'))
Vue.component('ModalContainer', () => import('@/components/ModalContainer'))
Vue.component('Navigation', () => import('@/components/Navigation.vue'))
Vue.component('CommunitySide', () => import('@/components/CommunitySide.vue'))
Vue.component('RightSideBar', () => import('@/components/RightSideBar.vue'))
Vue.component('SideMenu', () => import('@/components/SideMenu.vue'))
Vue.component('NewFeedBox', () => import('@/views/home/newsfeed/NewFeedBox.vue'))
Vue.component('FeedBox', () => import('@/views/home/newsfeed/FeedBox.vue'))
Vue.component('FeedSideBar', () => import('@/views/home/newsfeed/FeedSideBar.vue'))
Vue.component('FeedBanner', () => import('@/views/home/newsfeed/sidebar/FeedBanner.vue'))
Vue.component('StatisticBoxSection', () => import('@/views/home/dashboard/StatisticBoxSection'))
Vue.component('PropertyView', () => import('@/data-providers/projects/properties/PropertyView.vue'))
Vue.component('ObjectView', () => import('@/data-providers/projects/objects/ObjectView.vue'))
Vue.component('ObjectStandardView', () => import('@/data-providers/projects/object_standards/ObjectStandardView.vue'))
Vue.component('ObjectConditionView', () => import('@/data-providers/projects/object_conditions/ObjectConditionView.vue'))
Vue.component('MDCView', () => import('@/data-providers/projects/mdc/MDCView.vue'))
Vue.component('BxpImagesUploader', () => import('@/components/shared/forms/BxpImagesUploader.vue'))
Vue.component('WorkStatusDefinations', () => import('@/views/work/parts/WorkStatusDefinations'))
Vue.component('TeamJoinTabs', () => import('@/components/TeamJoinTabs.vue'))
Vue.component('AppllicationSummary', () => import('@/views/settings/teams/tabs/join-details/AppllicationSummary'))
Vue.component('ProfileTab', () => import('@/views/verifications/tabs/ProfileTab'))
Vue.component('WorkAppointmentBox', () => import('@/views/work/boxes/AppointmentBox.vue'))
Vue.component('CollaboratorBox', () => import('@/views/work/boxes/CollaboratorBox.vue'))
Vue.component('WorkTaskBox', () => import('@/views/work/boxes/TaskBox.vue'))
Vue.component('ContactTaskBox', () => import('@/views/work/boxes/ContactTaskBox.vue'))
Vue.component('CommunityPost', () => import('@/views/CommunityPost.vue'))
Vue.component('CommunityPostComment', () => import('@/views/CommunityPostComment.vue'))
Vue.component('ContactAppointmentBox', () => import('@/views/work/boxes/ContactAppointmentBox.vue'))
Vue.component('ContactResubmissionBox', () => import('@/views/work/boxes/ContactResubmissionBox.vue'))
Vue.component('WorkResubmissionBox', () => import('@/views/work/boxes/ResubmissionBox.vue'))
Vue.component('RootAwardActionButtons', () => import('@/views/work/action-buttons/in-lists/AwardActionButtons'))
Vue.component('RootDealActionButtons', () => import('@/views/work/action-buttons/in-lists/DealActionButtons'))
Vue.component('RootLeadActionButtons', () => import('@/views/work/action-buttons/in-lists/LeadActionButtons'))
Vue.component('RootOrderActionButtons', () => import('@/views/work/action-buttons/in-lists/OrderActionButtons'))
Vue.component('AssignWorkAction', () => import('@/views/work/action-buttons/AssignWorkAction.vue'))
Vue.component('Avatar', () => import('@/components/Avatar'))
Vue.component('AwardActionButtons', () => import('@/views/work/action-buttons/in-details/AwardActionButtons'))
Vue.component('DealActionButtons', () => import('@/views/work/action-buttons/in-details/DealActionButtons'))
Vue.component('OrderActionButtons', () => import('@/views/work/action-buttons/in-details/OrderActionButtons'))
Vue.component('WorkActionDropdown', () => import('@/views/work/action-buttons/WorkActionDropdown'))
Vue.component('BxpAdvancedDropdown', () => import('@/components/shared/BxpAdvancedDropdown.vue'))
Vue.component('BusinessListingDetail', () => import('@/views/customer/business-listings/BusinessListingDetail.vue'))
Vue.component('AwardedDealIcon', () => import('@/views/work/AwardedDealIcon.vue'))

// Vue.component('CommunityNotificationSettings', () => import('@/views/user/settings/CommunityNotificationSettings.vue'))

// BXP Components
Vue.component('BxpWizard', () => import('@/components/BxpWizard.vue'))
Vue.component('BxpReclamation', () => import('@/components/BxpReclamation.vue'))
Vue.component('BxpButtonNew', () => import('@/BXP/Buttons/BxpButtonNew'))
Vue.component('BxpRowActionDropdown', () => import('@/BXP/Buttons/BxpRowActionDropdown'))
Vue.component('BxpRowActionItem', () => import('@/BXP/Buttons/BxpRowActionItem'))
Vue.component('BxpReadMore', () => import('@/BXP/Content/BxpReadMore'))
Vue.component('BxpPricing', () => import('@/BXP/Content/BxpPricing'))
Vue.component('BillingSettings', () => import('@/views/workspace/BillingSettings'))
Vue.component('Icon', () => import('@/BXP/Content/Icon'))
Vue.component('BxpTextNewLine', () => import('@/BXP/Content/BxpTextNewLine'))
Vue.component('BxpInfoTooltip', () => import('@/BXP/Content/BxpInfoTooltip'))
Vue.component('BxpTableHeaderSummary', () => import('@/BXP/Content/BxpTableHeaderSummary'))
Vue.component('BxpTableViewSection', () => import('@/BXP/Content/BxpTableViewSection'))
Vue.component('BxpFilterPro2', () => import('@/BXP/Content/BxpFilterPro2'))
Vue.component('BxpPhoneDisplay', () => import('@/BXP/Content/BxpPhoneDisplay'))
Vue.component('BxpEmailDisplay', () => import('@/BXP/Content/BxpEmailDisplay'))
Vue.component('BxpLinkDisplay', () => import('@/BXP/Content/BxpLinkDisplay'))
Vue.component('BxpTagsDisplay', () => import('@/BXP/Content/BxpTagsDisplay'))
Vue.component('BxpAddressDisplay', () => import('@/BXP/Content/BxpAddressDisplay'))
Vue.component('BxpDebounceInput', () => import('@/BXP/Content/BxpDebounceInput'))
Vue.component('BxpShowOnce', () => import('@/BXP/Content/BxpShowOnce'))
Vue.component('BxpEditableField', () => import('@/BXP/Content/BxpEditableField'))

Vue.component('BxpNotificationSubscribe', () => import('@/BXP/Form/BxpNotificationSubscribe'))
Vue.component('BxpInput', () => import('@/BXP/Form/BxpInput'))
Vue.component('BxpInputNew', () => import('@/BXP/Form/BxpInputNew'))
Vue.component('BxpFormInput', () => import('@/BXP/Form/BxpFormInput'))
Vue.component('CustomValidationProvider', () => import('@/BXP/Form/CustomValidationProvider'))
Vue.component('BxpInputDuration', () => import('@/BXP/Form/BxpInputDuration'))
Vue.component('BxpInputText', () => import('@/BXP/Form/BxpInputText'))
Vue.component('BxpInputNumber', () => import('@/BXP/Form/BxpInputNumber'))
Vue.component('BxpInputTypeahead', () => import('@/BXP/Form/BxpInputTypeahead'))
Vue.component('BxpResultsComparison', () => import('@/BXP/Form/BxpResultsComparison'))
Vue.component('BxpRating', () => import('@/BXP/Form/BxpRating'))
Vue.component('BxpWorkReviewsRating', () => import('@/BXP/Form/BxpWorkReviewsRating'))
Vue.component('BxpRatingStars', () => import('@/BXP/Form/BxpRatingStars'))
Vue.component('BxpRecording', () => import('@/BXP/Form/BxpRecording'))
Vue.component('BxpShare', () => import('@/BXP/Form/BxpShare'))

Vue.component('BxpFileLink', () => import('@/BXP/Links/BxpFileLink.vue'))

Vue.component('BxpMenuContainer', () => import('@/BXP/Menu/BxpMenuContainer'))
Vue.component('BxpMenuGroup', () => import('@/BXP/Menu/BxpMenuGroup'))
Vue.component('BxpMenuLink', () => import('@/BXP/Menu/BxpMenuLink'))
Vue.component('BxpMenuSubGroup', () => import('@/BXP/Menu/BxpMenuSubGroup'))

Vue.component('BxpModalFooter', () => import('@/BXP/Modal/BxpModalFooter'))
Vue.component('BxpPageHeader', () => import('@/BXP/Page/BxpPageHeader'))
Vue.component('BxpTableFooterCount', () => import('@/BXP/Table/BxpTableFooterCount'))
Vue.component('BxpTableExportButton', () => import('@/BXP/Table/BxpTableExportButton'))
Vue.component('BxpTableExportBillsButton', () => import('@/BXP/Table/BxpTableExportBillsButton'))
Vue.component('BxpTableFullTextSearch', () => import('@/BXP/Table/BxpTableFullTextSearch'))
Vue.component('BxpTableFullTextToggle', () => import('@/BXP/Table/BxpTableFullTextToggle'))
Vue.component('BxpTableFooter', () => import('@/BXP/Table/BxpTableFooter'))
Vue.component('BxpTableOrderSelection', () => import('@/BXP/Table/BxpTableOrderSelection'))
Vue.component('BxpTableSmartView', () => import('@/BXP/Table/BxpTableSmartView'))
Vue.component('BxpTableStatusFilter', () => import('@/BXP/Table/BxpTableStatusFilter'))
Vue.component('BxpTableAssigneeFilter', () => import('@/BXP/Table/BxpTableAssigneeFilter'))
Vue.component('BxpTableGeneralFilter', () => import('@/BXP/Table/BxpTableGeneralFilter'))
Vue.component('BxpTableFromToFilter', () => import('@/BXP/Table/BxpTableFromToFilter'))
Vue.component('BxpTableHeaderSummaryActivator', () => import('@/BXP/Table/BxpTableHeaderSummaryActivator'))
Vue.component('BxpTableGroupedByFilter', () => import('@/BXP/Table/BxpTableGroupedByFilter'))
Vue.component('BxpTableSettingsFilter', () => import('@/BXP/Table/BxpTableSettingsFilter'))
Vue.component('BxpTableColumnSelectionPro', () => import('@/BXP/Table/BxpTableColumnSelectionPro'))
Vue.component('BxpTableGridChanger', () => import('@/BXP/Table/BxpTableGridChanger'))
Vue.component('BxpTableFilterActivator', () => import('@/BXP/Table/BxpTableFilterActivator'))
Vue.component('BxpTableViewSectionActivator', () => import('@/BXP/Table/BxpTableViewSectionActivator'))
Vue.component('BxpTableTabFilter', () => import('@/BXP/Table/BxpTableTabFilter'))
Vue.component('BxpTableUser', () => import('@/BXP/Table/BxpTableUser'))
Vue.component('BxpTableImage', () => import('@/BXP/Table/BxpTableImage'))
Vue.component('BxpTableComments', () => import('@/BXP/Table/BxpTableComments'))
Vue.component('BxpTableEmptyState', () => import('@/BXP/Table/BxpTableEmptyState'))

Vue.component('BxpTreeList', () => import('@/BXP/Tree/BxpTreeList'))
Vue.component('BxpWidgetContainer', () => import('@/BXP/Widgets/BxpWidgetContainer'))
Vue.component('BxpWidgetAddButton', () => import('@/BXP/Widgets/BxpWidgetAddButton'))
Vue.component('BxpWidgetActionButton', () => import('@/BXP/Widgets/BxpWidgetActionButton'))
Vue.component('BxpWorkEvent', () => import('@/BXP/Work/Events/BxpWorkEvent'))
Vue.component('BxpWorkEventIcon', () => import('@/BXP/Work/Events/BxpWorkEventIcon'))
Vue.component('BxpWorkEventLeft', () => import('@/BXP/Work/Events/BxpWorkEventLeft'))
Vue.component('BxpWorkEventRight', () => import('@/BXP/Work/Events/BxpWorkEventRight'))
Vue.component('BxpWorkEventDateTime', () => import('@/BXP/Work/Events/BxpWorkEventDateTime'))
Vue.component('BxpBusinessTeamOwnersSelection', () => import('@/components/BxpBusinessTeamOwnersSelection.vue'))

// Profile Page Components
Vue.component('BxpTab', () => import('@/BXP/Tab/BxpTab'))
Vue.component('BxpModalTabSelector', () => import('@/BXP/Tab/BxpModalTabSelector'))
Vue.component('BxpModalTabContainer', () => import('@/BXP/Tab/BxpModalTabContainer'))
Vue.component('BxpPageBackWithHeader', () => import('@/BXP/Page/BxpPageBackWithHeader'))
Vue.component('BxpContentManager', () => import('@/BXP/Content/BxpContentManager'))
Vue.component('BxpAccordion', () => import('@/BXP/Content/BxpAccordion'))

Vue.component('BxpOld', () => import('@/BXP/Content/BxpOld'))
Vue.component('BxpSendTicket', () => import('@/BXP/Content/BxpSendTicket'))
Vue.component('BxpScore', () => import('@/BXP/Content/BxpScore'))
Vue.component('ClientOnly', () => import('@/BXP/Content/BxpEmpty'))
Vue.component('BxpSalesProductPositions', () => import('@/BXP/Content/BxpSalesProductPositions'))
Vue.component('BxpOpeningTimes', () => import('@/BXP/Content/BxpOpeningTimes'))
Vue.component('CustomerReviews', () => import('@/components/CustomerReviews.vue'))
Vue.component('CustomerReviewItem', () => import('@/components/CustomerReviewItem.vue'))
Vue.component('ConversationWindow', () => import('@/components/chat/ConversationWindow.vue'))
Vue.component('ConversationTarget', () => import('@/components/chat/ConversationTarget.vue'))

Vue.component('FirstStepBox', () => import('@/views/home/dashboard/FirstStepBox.vue'))

Vue.component('AppSidebar', () => import('@/components/AppSidebar.vue'))

/**
 * Project Configurations
 * we should add the project comfigurations under this comment
 */
Vue.config.productionTip = false

/**
 * Using 3rd Part Modules
 * We should add the 3rd part module initializations under the this comment
 */
Vue.use(EmojiPickerPlugin)
Vue.use(VueSocialSharing)
Vue.use(VueVirtualScroller)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(NProgress, {
  latencyThreshold: 1000,
  router: true,
  showSpinner: true
})
Vue.use(Vue2TouchEvents)
Vue.use(VueSweetalert2)
Vue.use(Snotify)
Vue.use(SharedData)
Vue.use(AuthPlugin)
Vue.use(ServerCache)
Vue.use(Clipboard)
Vue.use(VueCurrencyFilter, {
  symbol: '€',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'end',
  symbolSpacing: true
})

Vue.use(Vue2CurrencyInput, {
  globalOptions: {
    currency: 'EUR',
    locale: 'de-DE',
    precision: 2,
    distractionFree: false
  },
  componentName: 'Vue2CurrencyInput'
})
Vue.use(VueSilentbox)


// Vue.use(VueReCaptcha, {
//   siteKey: '6LejWrMUAAAAAANzbYMNIJbL_X0_0qIwMXGrnAv4',
//   loaderOptions: {
//     useRecaptchaNet: true,
//     autoHideBadge: true // TODO: forbidden:
//     /* We need to place following text somewhere before release
//       This site is protected by reCAPTCHA and the Google
//       <a href="https://policies.google.com/privacy">Privacy Policy</a> and
//       <a href="https://policies.google.com/terms">Terms of Service</a> apply.
//     */
//   }
// })

/**
 * Global Custom Directives Loading
 * We should add the custom directive initializations under the this comment
 */
Vue.directive('permitted', IfPermitted)
Vue.directive('lazyload', LazyLoad)
Vue.directive('focus', Focus)
Vue.directive('shadow-html', ShadowHtml)
Vue.directive('click-outside', ClickOutside)

/**
 * Global Custom Filters Loading
 * We should add the custom filters initializations under the this comment
 */
Vue.filter('DateTimeFilter', DateTimeFilter)
Vue.filter('DateFilter', DateFilter)
Vue.filter('DownloadUrlFilter', DownloadUrlFilter)
Vue.filter('TimeFilter', TimeFilter)
Vue.filter('DateAgoFilter', DateAgoFilter)
Vue.filter('RelativeDateSimpleFilter', RelativeDateSimpleFilter)
Vue.filter('RelativeDateFilter', RelativeDateFilter)

window.nprogress = new NProgress()
window.router = router

// Rendering...
window.vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
