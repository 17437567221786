import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/core/auth'
import events from '@/core/events'
import hashTracker from '@/core/hashTracker'
// import socket from '@/core/socket'
import staticProjectData from '@/core/staticProjectData'
import permission from '@/core/permitted'
import modal from '@/core/modal'
import rest from '@/core/rest'
import alert from '@/core/alert'

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const routerOptions = {
  routes: [
    {
      path: '/auth',
      name: 'auth',
      meta: { guest: true },
      component: () => import('./views/containers/AuthContainer.vue'),
      children: [
        {
          path: 'login/:token?/:email?',
          name: 'login',
          component: () => import('./views/auth/Login.vue'),
          meta: { guest: true }
        },
        {
          path: 'signup/:email?',
          name: 'signup',
          component: () => import('./views/auth/Signup.vue'),
          meta: { guest: true }
        },
        {
          path: 'refresh-confirmation-link',
          name: 'refreshConfirmationLink',
          component: () => import('./views/auth/RefreshConfirmationLink.vue'),
          meta: { guest: true }
        },
        {
          path: 'renew',
          name: 'renew',
          component: () => import('./views/auth/Renew.vue'),
          meta: { guest: true }
        },
        {
          path: 'set-new-password/:token',
          name: 'setNewPassword',
          component: () => import('./views/auth/ChangePassword.vue'),
          meta: { guest: true }
        },
        {
          path: 'forgot-password/:msg?/:email?',
          name: 'forgotPassword',
          component: () => import('./views/auth/ForgotPassword.vue'),
          meta: { guest: true }
        },
        // {
        //   path: '/business-listing-home',
        //   name: 'businessListingHome',
        //   component: () => import('./views/customer/business-listings/BusinessListingHome.vue'),
        //   meta: {
        //     title: 'Business Listing Home',
        //     guest: true
        //   }
        // },
        // {
        //   path: '/business-companies',
        //   name: 'businessListingCompanies',
        //   component: () => import('./views/customer/business-listings/BusinessListingCompanies.vue'),
        //   meta: {
        //     title: 'Business Listing Companies',
        //     guest: true
        //   }
        // },
        {
          path: '/business-listings',
          name: 'businessListingList',
          component: () => import('./views/customer/business-listings/BusinessListingList.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        },
        {
          path: '/business-listings/:workspaceId/preview',
          name: 'businessListingPreview',
          component: () => import('./views/customer/business-listings/BusinessListingPreview.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        },
        {
          path: '/business-listings/:workspaceId',
          name: 'businessListingDetail',
          component: () => import('./views/customer/business-listings/BusinessListingDetail.vue'),
          meta: {
            title: 'Business Eintrag',
            guest: true
          }
        },
        {
          path: '/modernization-calculator/:uuid?',
          name: 'modernizationCalculator',
          component: () => import('@/components/MDCWizard/ModernizationCalculatorWizard.vue'),
          meta: {
            title: 'Modernisierungsrechner',
            guest: true
          },
          beforeEnter: async (to, from, next) => {
            // await staticProjectData.loadMdcCalcItems()
            next()
          }
        },
        {
          path: '/energy-certificate-calculator/:uuid?',
          name: 'energyCertificateCalculator',
          component: () => import('@/components/ECCWizard/EnergyCertificateCalculatorWizard.vue'),
          meta: {
            title: 'Energieausweis',
            guest: true
          },
          beforeEnter: async (to, from, next) => {
            next()
          }
        }
      ]
    },
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./views/containers/DashboardContainer.vue'),
      children: [
        // We have two same route because of this: https://github.com/vuejs/vue-router/issues/724
        {
          path: '/home',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            title: 'Dashboard'
          }
        },
        {
          path: '/inbox',
          name: 'inbox',
          component: () => import('./views/Inbox.vue'),
          meta: {
            title: 'Nachrichteneingang'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth && auth.user.email === 'leyla.friedhoff@pixelkultur.net') {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/contacts',
          name: 'contacts',
          component: () => import('./views/contacts/ContactList.vue'),
          meta: {
            title: 'Kontakte'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_ALL_CONTACTS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/e-bills',
          name: 'e-bills',
          component: () => import('./views/bills/EBillList.vue'),
          meta: {
            title: 'Kontakte'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_ALL_CONTACTS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/customer/offers',
          name: 'customerOffers',
          component: () => import('./views/customer/offers/Table.vue'),
          meta: {
            title: 'Meine Angebote'
          }
        },
        {
          path: '/customer/offers/:id',
          name: 'customerOfferDetail',
          component: () => import('./views/customer/offers/Detail.vue'),
          meta: {
            title: 'Angebotinformationen',
            guest: true
          }
        },
        {
          path: '/verification/:id',
          name: 'verificationDetail',
          component: () => import('./views/verifications/Detail.vue'),
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth && auth.user.isSpecialWorker && auth.hasPermitted(['EDIT_VERIFICATIONS'])) {
              return next()
            }
            router.push('/')
          },
          meta: {
            title: 'Verifizierungsdetails'
          }
        },
        {
          path: '/verifications',
          name: 'verifications',
          component: () => import('./views/verifications/VerificationList.vue'),
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth && auth.user.isSpecialWorker && auth.hasPermitted(['EDIT_VERIFICATIONS'])) {
              return next()
            }
            router.push('/')
          },
          meta: {
            title: 'Verifizierungen'
          }
        },
        {
          path: '/business-listing-verifications',
          name: 'businessListingVerifications',
          component: () => import('./views/business-listings/ListingVerificationList.vue'),
          meta: {
            title: 'Publikationen'
          }
          // beforeEnter: async (to, from, next) => {
          //   if (await permission.check('MANAGE_BUSINESS_LISTINGS')) {
          //     next()
          //   } else {
          //     router.push('/')
          //   }
          // }
        },
        {
          path: '/customer-satisfactions/:all?',
          name: 'customerSatisfactions',
          // component: () => import('./views/user/settings/workspace/CustomerSatisfactions.vue'),
          component: () => import('./views/business-listings/CustomerSatisfactionList.vue'),
          meta: {
            title: 'Kundenbewertungen'
          }
          // beforeEnter: async (to, from, next) => {
          //   if (await permission.check('EDIT_VERIFICATIONS')) {
          //     next()
          //   } else {
          //     router.push('/')
          //   }
          // }
        },

        {
          path: '/statistics/:id?',
          name: 'statistics',
          component: () => import('./views/qm/Statistics.vue'),
          meta: {
            title: 'Statistiken'
          },
          beforeEnter: async (to, from, next) => {
            if (auth.user.isSpecialWorker || (to.params.id && await auth.isTeamAdmin(to.params.id))) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/statistic-graphics/:id?',
          name: 'statistic-graphics',
          component: () => import('./views/qm/StatisticGraphics.vue'),
          meta: {
            title: 'Statistik Grafiken'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.user.isSpecialWorker || (to.params.id && await auth.isTeamAdmin(to.params.id))) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/custom-things/expert-teams',
          name: 'expert_team_list',
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'Auflistung'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.user && auth.user.isSpecialWorker) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/custom-things/:type/:subtype?',
          name: 'custom-things',
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'Statistik'
          },
          beforeEnter: async (to, from, next) => {
            if (to.params.subtype && typeof to.params.subtype === 'number') {
              if (auth.user.isSpecialWorker || (await auth.isTeamAdmin(to.params.subtype))) {
                return next()
              }
              router.push('/')
            } else {
              next()
            }

            // await auth.whenReadyPromise()
            // if (auth.user && (auth.user.isSpecialWorker || auth.user.id === 199)) {
            //   return next()
            // }
            // router.push('/')
          }
        },
        {
          path: '/custom-things2/:type/:subtype?',
          name: 'custom-things2',
          component: () => import('./views/qm/CustomThings.vue'),
          meta: {
            title: 'QM & QS'
          },
          beforeEnter: async (to, from, next) => {
            if (to.params.subtype && typeof to.params.subtype === 'number') {
              if (auth.user.isSpecialWorker || (await auth.isTeamAdmin(to.params.subtype))) {
                return next()
              }
              router.push('/')
            } else {
              next()
            }
          }
        },
        {
          path: '/settings/team/:id/settings/limits',
          name: 'expertlimitslist',
          component: () => import('./views/settings/teams/tabs/ExpertLimitsList.vue'),
          meta: {
            title: 'Sperren / Entsperren'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.isAdmin || (auth.hasPermitted(['SHOW_EXPERT_LIMIT_LIST'], auth.user.selectedWorkspace.id) && await auth.isInTeamBO(to.params.id))) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/settings/team/:id/settings/verifications',
          name: 'serviceVerifications',
          component: () => import('./views/settings/teams/tabs/Verifications.vue'),
          meta: {
            title: 'Leistungsverifizierung'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.isAdmin || (auth.hasPermitted(['SHOW_VERIFICATIONS_DETAIL'], auth.user.selectedWorkspace.id) && await auth.isInTeamBO(to.params.id))) {
              return next()
            }
            router.push('/')
          }
        },
        {
          path: '/profile/settings/:type?/:children?',
          name: 'profileSettings',
          component: () => import('./views/user/Settings.vue'),
          meta: {
            title: 'Profil Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            const routesWithPermissionsOnly = {
              usermanagement: ['EDIT_WORKSPACE_SETTINGS'],
              settingssubscription: ['EDIT_WORKSPACE_SETTINGS']
            }
            if (to.params && routesWithPermissionsOnly[to.params.type]) {
              await auth.whenReadyPromise()

              if (auth.hasPermitted(routesWithPermissionsOnly[to.params.type], auth.user.selectedWorkspace.id)) {
                return next()
              }
              router.push('/')
              return
            }
            next()
          }
        },
        {
          path: '/profile/:id?',
          name: 'profile',
          component: () => import('./views/user/Profile.vue'),
          meta: {
            title: 'Profil'
          }
        },
        {
          path: '/team-member-profile/:id',
          name: 'teamMemberProfile',
          component: () => import('./views/user/TeamMemberProfile.vue'),
          meta: {
            title: 'Mitglieds-Profil'
          }
        },
        {
          path: '/team-application-detail/:id',
          name: 'teamApplicationDetail',
          component: () => import('./views/user/TeamApplicationDetail.vue'),
          meta: {
            title: 'Bewerber'
          }
        },
        {
          path: '/company/:id',
          name: 'companyProfile',
          component: () => import('./views/user/CompanyProfile.vue'),
          meta: {
            title: 'Profil'
          }
        },
        {
          path: '/profile/:id',
          name: 'publicProfile',
          component: () => import('./views/user/Profile.vue'),
          meta: {
            title: 'Profil'
          }
        },
        // https://pixelkultur.atlassian.net/browse/V2-163
        {
          path: '/offers',
          name: 'offers',
          component: () => import('./views/offers/OfferList.vue'),
          props: true,
          meta: {
            title: 'Angebote'
          }
        },
        {
          path: '/offer/:id?/:draft?',
          name: 'offer',
          props: true,
          component: () => import('./views/offers/OfferDashboard.vue'),
          meta: {
            title: 'Angebot'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if ((to.params.workdata && to.params.workdata.award_id) || to.params.id || !(await auth.isLimitReached('Offer'))) {
              next()
            }
          }
        },
        {
          path: '/deliverynotes',
          name: 'deliverynotes',
          component: () => import('./views/offers/OfferList.vue'),
          props: true,
          meta: {
            title: 'Lieferscheine'
          }
        },
        {
          path: '/deliverynote/:id?',
          name: 'deliverynote',
          component: () => import('./views/offers/OfferDashboard.vue'),
          meta: {
            title: 'Lieferschein'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if ((to.params.workdata && to.params.workdata.award_id) || to.params.id || !(await auth.isLimitReached('DeliveryNote'))) {
              next()
            }
          }
        },
        {
          path: '/orderconfirmations',
          name: 'orderconfirmations',
          component: () => import('./views/offers/OfferList.vue'),
          props: true,
          meta: {
            title: 'Auftragsbestätigungen'
          }
        },
        {
          path: '/orderconfirmation/:id?',
          name: 'orderconfirmation',
          component: () => import('./views/offers/OfferDashboard.vue'),
          meta: {
            title: 'Auftragsbestätigung'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if ((to.params.workdata && to.params.workdata.award_id) || to.params.id || !(await auth.isLimitReached('OrderConfirmation'))) {
              next()
            }
          }
        },
        {
          path: '/bills',
          name: 'bills',
          component: () => import('./views/offers/OfferList.vue'),
          meta: {
            title: 'Rechnungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_BILLS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/bill/:id?',
          name: 'bill',
          component: () => import('./views/offers/OfferDashboard.vue'),
          meta: {
            title: 'Rechnung'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if ((to.params.workdata && to.params.workdata.award_id) || to.params.id || !(await auth.isLimitReached('Bill'))) {
              next()
            }
          }
        },
        {
          path: '/cbills/:type?',
          name: 'cbills',
          component: () => import('./views/bills/BillList.vue'),
          meta: {
            title: 'Kundenrechnungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_BILLS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/provisions/:type?',
          name: 'provisions',
          props: true,
          component: () => import('./views/provisions/ProvisionList.vue'),
          meta: {
            title: 'Provisionsmeldungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_BILLS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/qm/:type/lost',
          name: 'lostWorks',
          component: () => import('./views/qm/LostAwardList.vue'),
          beforeEnter: async (to, from, next) => {
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                router.push('/')
              }
            } else {
              next()
            }
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/qm/reviews/:teamId/statistics',
          name: 'reviewWorksStatistics',
          component: () => import('./views/qm/reviews/ReportedReviewWorks.vue'),
          beforeEnter: async (to, from, next) => {
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                router.push('/')
              }
            } else {
              next()
            }
          },
          meta: {
            title: 'Statistik'
          }
        },
        {
          path: '/qm/reviews/:teamId/:status/',
          name: 'reviewWorks',
          component: () => import('./views/qm/reviews/WorkReviewList.vue'),
          beforeEnter: async (to, from, next) => {
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                next(false)
                return router.push('/')
              }
            } else {
              next()
            }
          },
          meta: {
            title: 'Bewertungen'
          }
        },
        {
          path: '/works/:type/:subtype?',
          name: 'works',
          props: true,
          component: () => import('./views/work/WorkList.vue'),
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (to.params.type === 'award') {
              if (await permission.check('SHOW_AWARD_MENU')) {
                next()
              } else {
                return router.push('/')
              }
            }
            if (to.params.type === 'lead' && !auth.hasPermitted(['SHOW_LEADS'])) {
              next(false)
              return router.push('/')
            }
            next()
          },
          meta: {
            title: 'Verkauf'
          }
        },
        {
          path: '/billing/settings',
          name: 'billingSettings',
          component: () => import('./views/workspace/BillingSettings.vue'),
          meta: {
            title: 'Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/workspace/settings',
          name: 'workspaceSettings',
          component: () => import('./views/workspace/WorkspaceSettings.vue'),
          meta: {
            title: 'Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/workspace/workspace-settings',
          name: 'settings',
          component: () => import('./views/workspace/Settings.vue'),
          meta: {
            title: 'Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/workspace/user-management',
          name: 'workspaceUserManagement',
          component: () => import('./views/workspace/UserManagement.vue'),
          meta: {
            title: 'Benutzerverwaltung'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_WORKERS_DETAIL')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        // {
        //   path: '/workspace/business-listing',
        //   name: 'businessListing',
        //   component: () => import('./views/business-listings/BusinessListing.vue'),
        //   meta: {
        //     title: 'Business Listing'
        //   }
        // },
        {
          path: '/listing-settings/:workspaceId?',
          name: 'listingSettings',
          props: true,
          component: () => import('./views/business-listings/ListingSettings.vue'),
          meta: {
            title: 'Business Listing Einstellungen'
          },
          beforeEnter: async (to, from, next) => {
            if ((!to.params.workspaceId && (await permission.check('EDIT_BUSINESS_LISTING'))) || (to.params.workspaceId && (await permission.check('MANAGE_BUSINESS_LISTINGS')))) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/review/:id',
          name: 'review',
          component: () => import('./views/reviews/ReviewDashboard.vue'),
          meta: {
            title: 'Business Eintrag Kundenbewertung'
          }
        },
        {
          path: '/list-members/:type/:id',
          name: 'listMembers',
          component: () => import('./views/user/UserList.vue'),
          meta: {
            title: 'Partnerliste'
          }
        },
        {
          path: '/list-applications/:id',
          name: 'listApplications',
          component: () => import('./views/settings/teams/tabs/TeamApplicationList.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_COORPERATIONS_PARTNER_DETAIL')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Bewerbungsliste'
          },
          props: true
        },
        {
          path: '/qm/:type/:id',
          name: 'reportedAwards',
          component: () => import('./views/qm/ReportedAwardsList.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_QUALITY_MANAGER_MENU')) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Gemeldete Aufträge'
          }
        },
        {
          // path: '/work/:id/:suggestionId?',
          path: '/work/:id/:tab?',
          name: 'work',
          props: true,
          component: () => import('./views/work/WorkDashboard.vue')
        },
        {
          path: '/downloads/:id',
          name: 'downloads',
          component: () => import('./views/downloads/DownloadsList.vue'),
          meta: {
            title: 'Downloads'
          },
          beforeEnter: async (to, from, next) => {
            if (await auth.isInTeamBO(to.params.id) || await auth.isInTeamSV(to.params.id)) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/projects',
          name: 'projects',
          component: () => import('./views/projects/ProjectList.vue'),
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.user.selectedWorkspace.plugins.includes('projects') /* await permission.check('SHOW_PROTOCOL_MENU') */) {
              await staticProjectData.loadAll()
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Protokoll'
          }
        },
        {
          path: '/project/:id/:tab?',
          name: 'project',
          component: () => import('./views/projects/ProjectDashboard.vue'),
          beforeEnter: async (to, from, next) => {
            await staticProjectData.loadAll()
            next()
          },
          meta: {
            title: 'Projekt'
          }
        },
        {
          path: '/support/:page?',
          name: 'support',
          component: () => import('./views/support/Support.vue'),
          meta: {
            title: 'Hilfe & Support'
          }
        },
        {
          path: '/settings/team/:id/details',
          name: 'team-detail',
          component: () => import('./views/settings/teams/TeamDetail.vue'),
          meta: {
            title: 'Business Team Detail'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (!auth.user.selectedWorkspace.isDemo) {
              next()
            } else {
              router.push('/')
              alert.info('Mit einem Demo Workspace können Sie keinem Business Team beitreten, ' +
                  'bitte wechseln Sie zu Ihrem Hauptworkspace.')
            }
          }
        },
        {
          path: '/settings/team/:id/join/:tab?',
          name: 'team-join',
          component: () => import('./views/settings/teams/TeamJoin.vue'),
          meta: {
            title: 'Business Team Bewerbung'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (!auth.user.selectedWorkspace.isDemo) {
              next()
            } else {
              router.push('/')
              alert.info('Mit einem Demo Workspace können Sie keinem Business Team beitreten, ' +
                  'bitte wechseln Sie zu Ihrem Hauptworkspace.')
            }
          }
        },
        {
          path: '/settings/team/:id/settings/:type?',
          name: 'groupSettings',
          component: () => import('./views/settings/teams/TeamSettings.vue'),
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_COORPERATIONS_PARTNER_DETAIL') && await auth.isInTeamBO(to.params.id)) {
              next()
            } else {
              router.push('/')
            }
          },
          meta: {
            title: 'Business Team Informationen'
          }
        },
        {
          path: '/settings/base-data',
          name: 'baseDatas',
          component: () => import('./views/settings/base-data/BaseDataIndex.vue'),
          meta: {
            title: 'Stammdaten'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/products',
          name: 'baseDataProducts',
          component: () => import('./views/settings/base-data/ProductList.vue'),
          meta: {
            title: 'Artikel'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/services',
          name: 'baseDataServices',
          component: () => import('./views/settings/base-data/ServiceList.vue'),
          meta: {
            title: 'Auftragsarten'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/base-data/text-snippets',
          name: 'baseDataTextSnippets',
          component: () => import('./views/settings/base-data/TextSnippetList.vue'),
          meta: {
            title: 'Textvorlagen'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('SHOW_STAMMDATEN_MENU')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '/settings/teams',
          name: 'teams',
          component: () => import('./views/settings/teams/TeamList.vue'),
          meta: {
            title: 'Business Teams'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (!auth.user.selectedWorkspace.isDemo &&
              ((auth.user.teamMemberships.length || auth.user.permittedOwners.filter(o => o.is_team).length) ||
              (auth.user.teamInvitations.length && auth.user.id === auth.user.selectedWorkspace.parent_id))) {
              next()
            } else {
              router.push('/')
              alert.info('Mit einem Demo Workspace können Sie keinem Business Team beitreten, ' +
                  'bitte wechseln Sie zu Ihrem Hauptworkspace.')
            }
          }
        },
        {
          path: '/workshops/:teamId',
          name: 'workshops',
          component: () => import('./views/workshop/WorkshopList.vue'),
          meta: {
            title: 'Seminarverwaltung'
          }
        },
        {
          path: '/workshopSelection/:teamId',
          name: 'workshopSelections',
          component: () => import('./views/workshop/WorkshopListExperts.vue'),
          meta: {
            title: 'Seminare'
          }
        },
        {
          path: '/baseData/:type',
          name: 'baseData',
          component: () => import('./views/baseData/BaseDataList.vue'),
          meta: {
            title: 'Stammdaten'
          }
        },
        {
          path: '/tasks/:id?',
          name: 'tasks',
          component: () => import('./views/tasks/TaskList.vue'),
          meta: {
            title: 'Aufgaben'
          }
        },
        {
          path: '/resubmissions/:id?',
          name: 'resubmissions',
          component: () => import('./views/resubmissions/ResubmissionList.vue'),
          meta: {
            title: 'Wiedervorlagen'
          }
        },
        {
          path: '/appointments',
          name: 'appointments',
          component: () => import('./views/appointments/AppointmentList.vue'),
          meta: {
            title: 'Termine'
          }
        },
        {
          path: '/calendar',
          name: 'calendar',
          component: () => import('./views/appointments/CalendarList.vue'),
          meta: {
            title: 'Termine'
          }
        },
        {
          path: '/objects',
          name: 'objects',
          component: () => import('./views/objects/ObjectList.vue'),
          meta: {
            title: 'Objekte'
          }
        },
        {
          path: '/customer-modernizations/:status',
          name: 'customerModernizations',
          component: () => import('./views/customer-modernizations/CustomerModernizationList.vue'),
          meta: {
            title: 'Modernisierungsanfragen'
          }
        },
        {
          path: '/customer-energy-certificates/:status',
          name: 'customerEnergyCertificates',
          component: () => import('./views/customer-energy-certificates/CustomerEnergyCertificateList.vue'),
          meta: {
            title: 'Energieausweis Anfragen'
          }
        },
        {
          path: '/administration',
          name: 'administration',
          component: () => import('./views/administration/Index.vue'),
          meta: {
            title: 'Administration'
          }
        },
        {
          path: '/administration/users',
          name: 'administration_users',
          component: () => import('./views/administration/users/UserList.vue'),
          meta: {
            title: 'User List'
          }
        },
        {
          path: '/administration/sms_test',
          name: 'administration_sms_test',
          component: () => import('./views/administration/users/SmsTest.vue'),
          meta: {
            title: 'User Sms Tester'
          }
        },
        {
          path: '/chats',
          name: 'chats',
          component: () => import('./views/user/Chats.vue'),
          meta: {
            title: 'Chats'
          },
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.user.permittedOwners.filter(o => o.is_team).length ||
              auth.user.teamMemberships.length ||
              auth.hasPermitted(['SHOW_COMMUNICATIONS'])) {
              return next()
            }
            router.push('/')
          }
        },

        {
          path: 'chatgpt',
          name: 'chatgpt',
          component: () => import('./views/user/ChatGPT.vue'),
          meta: {
            title: 'ChatGPT'
          }
        },
        {
          path: 'openai',
          name: 'openai',
          component: () => import('./views/user/OpenAI.vue'),
          meta: {
            title: 'OpenAi'
          }
        },
        {
          path: 'workimporteds',
          name: 'workimporteds',
          component: () => import('./views/user/WorkImportedList.vue'),
          meta: {
            title: 'WorkImportedList'
          }
        },
        {
          path: 'nylas',
          name: 'nylas',
          component: () => import('./views/user/Nylas.vue'),
          meta: {
            title: 'Nylas'
          }
        },
        {
          path: '/subscription/:type?',
          name: 'subscription',
          component: () => import('./views/user/Subscription.vue'),
          meta: {
            title: 'Abonnement'
          },
          beforeEnter: async (to, from, next) => {
            if (await permission.check('EDIT_WORKSPACE_SETTINGS')) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        // {
        //   path: '/posts/:id?',
        //   name: 'postDetail',
        //   component: () => import('./views/PostDetail.vue'),
        //   meta: {
        //     title: 'Post'
        //   }
        // },
        {
          path: '/changelogs',
          name: 'changelogs',
          component: () => import('./views/ChangeLogs.vue'),
          meta: {
            title: 'Changelogs'
          }
        },
        {
          path: '/community',
          name: 'community',
          component: () => import('./views/Community.vue'),
          meta: {
            title: 'Community'
          },
          children: [
            {
              path: 'notifications',
              name: 'communityNotificationSettings',
              component: () => import('./views/user/settings/CommunityNotificationSettings.vue')
            }, {
              path: ':categoryId?',
              name: 'communityBody',
              component: () => import('./views/CommunityBody.vue')
            }, {
              path: 'post/:id/:commentId?',
              name: 'communityPost',
              component: () => import('./views/CommunityPost.vue')
            }
          ],
          beforeEnter: async (to, from, next) => {
            await auth.whenReadyPromise()
            if (auth.user.isBxpUser) {
              next()
            } else {
              router.push('/')
            }
          }
        },
        {
          path: '*',
          redirect: 'home'
        }
      ]
    }
  ]
}

// if (window.location.hostname === 'modernization.pixelkultur.net') {
//   routerOptions = {
//     routes: [{
//       path: '/:uuid?',
//       name: 'modernizationCalculator',
//       component: () => import('@/components/MDCWizard/ModernizationCalculatorWizard.vue'),
//       meta: {
//         title: 'Modernisierungsrechner',
//         guest: true
//       }
//     }],
//     mode: 'history'
//   }
// }

var router = new Router(routerOptions)

// We should detect if the user click the prev button of the browser.
window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

router.beforeEach(async (to, from, next) => {
  // If the developer used hash trackter for form changes, we should validate
  // if everything is same or not
  if (hashTracker.initial && hashTracker.initial !== hashTracker.current) {
    // If there are some unsaved data, we should ask to the user what should we do
    hashTracker.askConfirmation(
      // Save the form and go to the next route
      async () => {
        // We should set the "what-happend-after-save".
        hashTracker.setRelease(() => {
          // We should clear the hash tracker data
          hashTracker.clear()
          // And redirect to the next route
          next()
        })
        // Call the save method
        await hashTracker.method()
      },
      // Cancel and back to the form
      () => {
        return next(false)
      },
      // Discard
      () => {
        // We should clear the data
        hashTracker.clear()
        // We don't need to do anything.
        next()
      }
    )

    return
  }

  // We should clear the hash tracker if everything is ok
  hashTracker.clear()

  // If the user hasn't clicked the back button of the browser, we shouldn't close
  // the modals. It could be a manual route trigger (this.$router.push()).
  if (window.popStateDetected) {
    // We should reset the value of back button tracker.
    window.popStateDetected = false
    // if we're in a modal, we should close the modal instead of changing route
    // TODO: we should replace our $swal modals with our modals, this is a dirty solution
    const tmpItems = modal.items.filter(o => o.id !== 'gptModal')
    if (window.vue && window.vue.$swal.isVisible()) {
      window.vue.$swal.close()
      return next(false)
    } else if (tmpItems.length) {
      const topModal = tmpItems[tmpItems.length - 1]

      // Some modals, for example terms modal can't close by clicking the back button
      if (!topModal.cantCloseByBackButton) {
        modal.close(topModal.uuid)
      }

      return next(false)
    }
  }

  // We can change the title of the page
  let title = 'pixelkultur'
  if (to.meta.title) {
    title += ' | ' + to.meta.title
  }
  document.title = title

  document.body.style.overflow = ''

  // We should show a loading bar on the top
  events.fire('route@beforeEach')
  window.nprogress.start()

  const isTeamAdmin = [
    'statistics',
    'statistic-graphics',
    'custom-things',
    'custom-things2'
  ]

  const isInTeam = [
    'expertlimitslist',
    'downloads',
    'serviceVerifications',
    'groupSettings'
  ]

  const teamId = to.params[to.name.includes('custom-things') ? 'subtype' : 'id']

  if (to.meta.guest !== true && auth.loggedIn === false) {
    next({ name: 'login', params: { path: to.fullPath } })
  } else if (
    to.matched.some(o => o.path === '/auth') &&
    auth.loggedIn === true &&
    !to.matched.some(o => o.path.includes('business')) &&
    !to.matched.some(o => o.path.includes('modernization-calculator')) &&
    !to.matched.some(o => o.path.includes('energy-certificate-calculator'))) {
    console.log('to', to)
    next({ name: 'home' })
    events.fire('route@afterEach')
    window.nprogress.done()
  } else if (to.meta.guest === true && auth.loggedIn && auth.expire_at) {
    next({ name: 'home' })
  } else if (isInTeam.includes(to.name)) {
    await auth.whenReadyPromise()

    if (auth.user.isSpecialWorker || (teamId && (await auth.isInTeamBO(teamId) || await auth.isInTeamSV(teamId)))) {
      next()
    } else {
      next('/')
    }
  } else if (isTeamAdmin.includes(to.name)) {
    await auth.whenReadyPromise()

    if (auth.user.isSpecialWorker || (teamId && await auth.isTeamAdmin(teamId))) {
      next()
    } else {
      next('/')
    }
  } else if (['offer', 'bill', 'orderconfirmation', 'deliverynote'].includes(to.name)) {
    await auth.whenReadyPromise()
    const mapping = {
      offer: 'Offer',
      orderconfirmation: 'OrderConfirmation',
      deliverynote: 'DeliveryNote',
      bill: 'Bill'
    }
    const type = mapping[to.name]

    if ((to.params.workdata && to.params.workdata.award_id) || to.params.id || !(await auth.isLimitReached(type))) {
      next()
    } else {
      next({ name: to.name + 's' })
    }
  } else if (to.matched.some(record => record.name === 'works')) {
    await auth.whenReadyPromise()

    if (to.params.type === 'award' && !await permission.check('SHOW_AWARD_MENU')) {
      return next('/')
    } else if (to.params.type === 'lead' && !await permission.check('SHOW_LEADS')) {
      return next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  // We should reset the status
  window.popStateDetected = false

  // We should clear this value for side menu links
  auth.sideMenuCode = null

  // We are done!
  window.nprogress.done()

  const data = {
    name: to.name,
    full_path: to.fullPath,
    query: to.query,
    params: to.params
  }
  // We should notify to sub menu after change
  events.fire('route@afterEach')
  events.fire('route@updated', data)

  // We should send the route changes to the websocket
  // We can open this feature maybe lates.
  // socket.send('route@update', data)

  if (auth.user && auth.user.appversion) {
    handleAppUpdate(auth.user.appversion)
  }
})

function handleAppUpdate (version) {
  const localVersion = localStorage.getItem('appversion')
  const newVersion = version
  if (localVersion) {
    if (newVersion && cmpVersions(localVersion, newVersion)) {
      rest.cancelAllRequests()
      localStorage.setItem('appversion', newVersion)
      caches.keys().then((keyList) => {
        return Promise.all(keyList.map((key) => caches.delete(key)))
      }).catch((error) => {
        console.error('Error during cache deletion:', error)
      }).finally(() => {
        window.location.reload(true)
      })

      // modal.open({
      //   component: () => import('@/views/auth/modals/AppUpdate.vue'),
      //   classes: {
      //     dialog: 'modal-full-screen modal-workspace-alert',
      //     content: 'modal-wizard'
      //   },
      //   data: {
      //     cantCloseByBackButton: true,
      //     modalType: 'fullscreen'
      //   },
      //   onClose: (data) => {
      //     if (!data) {
      //       // user chose remember later, so we reset it to old version
      //       localStorage.setItem('appversion', localVersion)
      //     } else {
      //       window.location.href = window.location.href.replace(/#.*$/, '')
      //     }
      //   }
      // })
    }
  } else {
    localStorage.setItem('appversion', newVersion)
  }
}

function cmpVersions (a, b) {
  let i, diff
  const regExStrip0 = /(\.0+)+$/
  const segmentsA = a.replace(regExStrip0, '').split('.')
  const segmentsB = b.replace(regExStrip0, '').split('.')
  const l = Math.min(segmentsA.length, segmentsB.length)

  for (i = 0; i < l; i++) {
    diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10)
    if (diff) {
      return diff
    }
  }
  return segmentsA.length - segmentsB.length
}

export default router
